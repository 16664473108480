import React, { ReactNode } from 'react';
import styles from './index.module.less';

const BaseLinearContent = ({ width = 10, children }: { width?: number; children?: ReactNode }) => {
  return (
    <div className={styles.content}>
      <div className={styles.leftCover} />
      <div className={styles.rightCover} />
      {children}
    </div>
  );
};

export default BaseLinearContent;
