import React from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import BaseLinearContent from '@/components/Common/BaseLinearContent';
import classNames from 'classnames';
import Image from 'next/image';

const ChooseContent = () => {
  const { t } = useTranslation();

  const h3List: string[] = [
    'Use advanced AI technology to try on any hairstyle without cutting your hair',
    'Extensive Updated hair cut Library:collection of haircut from top influencers and professional stylists',
    'Super Fast:No long waits—get your hairstyle in about 5 seconds on average',
    'Save Big:Even No Subscription ,Just One-time Payment,Pay As You Go',
  ];

  const dataList = [
    {
      title: t('Virtual Hairstyle Try-On'),
      desc: t('Use advanced AI technology to try on any hairstyle without cutting your hair.'),
    },
    {
      title: t('Extensive Hairstyle Library'),
      desc: t(
        'Access a vast collection of hairstyles from top influencers and professional stylists. See how you look with the newest styles before making any changes.',
      ),
    },
    {
      title: t('Super Fast'),
      desc: t('No long waits, get your hairstyle virtual try-on in about 5 seconds on average.'),
    },
    {
      title: t('Save Big'),
      desc: t(
        "Custom styles by top stylists can run you <span style='color:#ffffff'>$100</span> to <span style='color:#ffffff'>$300</span> or more. At Hairpaca, try on a variety of hairstyles starting at just <span style='color:#68F3F6'>$4.99</span> !",
      ),
    },
  ];

  return (
    <div className={styles.content}>
      <h2>
        <span>{t('Why Choose Hairpaca')}</span>
      </h2>

      <BaseLinearContent>
        <div className={styles.container}>
          {dataList.map((item, index) => {
            return (
              <div className={styles.cardContainer} key={index + item.title}>
                <Image
                  src={`/images/hairstyle/choose-icon-${index}.png`}
                  alt={'icon'}
                  width={88}
                  height={88}
                />

                <p className={classNames(styles.title)}>{item.title}</p>
                <p className={styles.desc} dangerouslySetInnerHTML={{ __html: item.desc }} />
              </div>
            );
          })}
        </div>
        <ul style={{ display: 'none' }}>
          {h3List.map((item, index) => {
            return <h3 key={index + item}>{item}</h3>;
          })}
        </ul>
      </BaseLinearContent>
    </div>
  );
};

export default ChooseContent;
